import type { RouteLocationNormalized } from 'vue-router'

import { useAuthStore } from './store'

export const authMiddleware = async (to: RouteLocationNormalized) => {
  if (!to.meta.auth) {
    return true
  }
  const store = useAuthStore()
  await store.fetchMe()
  return true
}
