import { uniqueId } from 'lodash'
import { computed, inject, type InjectionKey, type PropType, provide, type Ref, ref } from 'vue'

import type { Props } from '@/types/compositionApi'

import { type Requirement, RequirementStatus } from './constants'

export const useUniqueId = (prefix?: string) => computed(() => uniqueId(prefix))

/**
 * Shares reusable parts for FormField and FormRow.
 * FormField can be used to wrap input without label whereas FormRow provides
 * fully-featured form row. FormElement itself can be used as a standalone component
 * to provide (empty) context for inputs which don't need a row or a field wrapper.
 */

export type FormMessage = string | undefined
export type FormMessages = FormMessage[]

export const useFormElementProps = () => ({
  error: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  validated: { type: Boolean, default: false },
  errors: { type: Array as PropType<FormMessages>, default: () => [] },
  infos: { type: Array as PropType<FormMessages>, default: () => [] },
  warnings: { type: Array as PropType<FormMessages>, default: () => [] },
  requirements: { type: Array as PropType<Requirement[]>, default: () => [] }
})

export const ERROR = Symbol('error') as InjectionKey<Ref<boolean>>
export const DISABLED = Symbol('disabled') as InjectionKey<Ref<boolean>>
export const ERRORS = Symbol('errors') as InjectionKey<Ref<FormMessages>>
export const INFOS = Symbol('infos') as InjectionKey<Ref<FormMessages>>
export const WARNINGS = Symbol('warnings') as InjectionKey<Ref<FormMessages>>
export const REQUIREMENTS = Symbol('requirements') as InjectionKey<Ref<Requirement[]>>

export function useFormElement(props: Props<typeof useFormElementProps>) {
  const hasError = computed(() => {
    const errors = (Array.isArray(props.errors) ? props.errors : [props.errors]).filter(Boolean)

    const hasErrors = errors.length > 0
    const hasRequirements = props.requirements.some(
      ({ status }) => status === RequirementStatus.Rejected
    )
    return props.error || hasErrors || hasRequirements
  })

  provide(
    ERROR,
    computed(() => hasError.value)
  )
  provide(
    DISABLED,
    computed(() => props.disabled)
  )
  provide(
    ERRORS,
    computed(() => (props.validated ? props.errors : []))
  )
  provide(
    INFOS,
    computed(() => props.infos)
  )
  provide(
    WARNINGS,
    computed(() => (props.validated ? props.warnings : []))
  )
  provide(
    REQUIREMENTS,
    computed(() => props.requirements)
  )

  return { hasError }
}

/**
 * Shares reusable parts for inputs wrapped by InputWrapper. Used by InputText,
 * InputTextMulti and Textarea.
 */
export const useInputWrappedProps = () => ({
  disableBlur: { type: Boolean, default: false },
  placeholder: { type: String, default: '' },
  optional: { type: Boolean, default: false }
})

export const useInputWrappedEmits = () => ['focus', 'blur', 'update:modelValue']

export function useInputWrapped(
  props: Props<typeof useInputWrappedProps>,
  emits: {
    (e: 'update:modelValue', v: string): void
    (e: 'focus', v: Event): void
    (e: 'blur', v: Event): void
  }
) {
  const disabled = inject(DISABLED, () => ref(false), true)
  const error = inject(ERROR, () => ref(false), true)

  const focused = ref(false)

  const showPlaceholder = computed(() => {
    if (!props.optional && !props.placeholder) {
      return undefined
    }

    return props.optional ? 'This field is optional.' : props.placeholder
  })

  const onFocus = (event: Event) => {
    focused.value = true
    emits('focus', event)
  }

  const onBlur = (event: Event) => {
    if (!props.disableBlur) {
      focused.value = false
      emits('blur', event)
    }
  }

  const inputHandler = (
    _event: Event,
    input: HTMLInputElement | HTMLTextAreaElement,
    transform: ((v: string) => string)[]
  ): string => {
    let { value } = input
    if (transform.length && value) {
      let cursor = input.selectionStart
      if (cursor === null) {
        cursor = value.length
      }
      const transformedValue = transform.reduce((val, foo) => foo(val), value)
      // eslint-disable-next-line no-param-reassign
      input.value = transformedValue
      if (transformedValue.length < value.length && cursor > 0) {
        // symbol was discarded
        cursor -= 1
      }
      // non-text inputs (email for example) do not support selection of text
      const inputType = input.type
      const allowedTypeForSelection = 'text'
      if (input instanceof HTMLInputElement && inputType !== allowedTypeForSelection) {
        // eslint-disable-next-line no-param-reassign
        input.type = allowedTypeForSelection
      }
      input.setSelectionRange(cursor, cursor)
      if (input instanceof HTMLInputElement && inputType !== allowedTypeForSelection) {
        // eslint-disable-next-line no-param-reassign
        input.type = inputType
      }
      value = transformedValue
    }

    emits('update:modelValue', value)

    return value
  }

  return {
    disabled,
    error,
    focused,
    showPlaceholder,
    onFocus,
    onBlur,
    inputHandler
  }
}

export const useErrorAndDisabled = () => {
  const disabled = inject(DISABLED, () => ref(false), true)
  const error = inject(ERROR, () => ref(false), true)

  return {
    disabled,
    error
  }
}
