<template>
  <div :class="{ [size]: true }" class="avatar" data-qa="avatar">
    <img v-if="url" class="avatar-image" :src="url" :alt="name || undefined" />
    <span v-else class="name">{{ abbrev }}</span>
    <div class="avatar-overlay" />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

const props = defineProps({
  size: { type: String as PropType<'sm' | 'md' | 'lg'>, default: 'md' },
  name: { type: String as PropType<string | null | undefined> },
  url: { type: String as PropType<string | null | undefined> }
})

const abbrev = computed(() =>
  (props.name || '')
    .trim()
    .split(' ')
    .slice(0, 2)
    .map((str) => str[0]?.toUpperCase())
    .join('')
)
</script>

<style scoped lang="postcss">
.avatar {
  @apply relative flex flex-shrink-0 flex-grow-0 select-none items-center justify-center overflow-hidden rounded-full font-bold text-white shadow-card;

  background-image: linear-gradient(360deg, #b0b0b0 0%, #626262 100%);

  &.sm {
    @apply h-5 w-5 text-sm;
  }

  &.md {
    @apply h-9 w-9 text-base;
  }

  &.lg {
    @apply h-32 w-32 text-3xl;
  }

  .avatar-image {
    @apply h-full w-full object-cover;
  }

  .avatar-overlay {
    @apply pointer-events-none absolute inset-0 rounded-full;
  }
}
</style>
