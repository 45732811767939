import { createRouter, createWebHistory, type RouterOptions } from 'vue-router'

import EmptyLayout from '@/layouts/EmptyLayout.vue'
import acceptMeetingRoutes from '@/modules/accept-event/routes'
import { authMiddleware } from '@/modules/auth/middleware'
import authRoutes from '@/modules/auth/routes'
import homeRoutes from '@/modules/home/routes'
import newMeetingRoutes from '@/modules/new-meeting/routes'
import privacyPolicyRoutes, {
  PRIVACY_POLICY_DE,
  PRIVACY_POLICY_EN
} from '@/modules/privacy-policy/routes'
import termsOfServiceRoutes from '@/modules/terms-of-service/routes'

const localesRoutesHandler = new Map<string, Map<string, string>>([
  [
    '/privacy-policy',
    new Map<string, string>([
      ['de', PRIVACY_POLICY_DE],
      ['en', PRIVACY_POLICY_EN]
    ])
  ]
])

// Setting up the routes
const router = createRouter(<RouterOptions>{
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,
    ...homeRoutes,
    ...newMeetingRoutes,
    ...acceptMeetingRoutes,
    ...privacyPolicyRoutes,
    ...termsOfServiceRoutes,
    {
      path: '/500',
      component: () => import('@/views/500.vue'),
      meta: {
        layout: EmptyLayout,
        auth: false
      }
    }
  ]
})

//Middleware
router.beforeEach(authMiddleware)

// Localize routes
router.beforeEach((to, from, next) => {
  const locale = localStorage.getItem('lang') || 'en'
  const handler = localesRoutesHandler.get(to.path)
  if (handler) {
    next({ name: handler.get(locale) })
  } else {
    next()
  }
})

export default router
