<template>
  <Popup :open="open" @close="$emit('close')">
    <PopupContainer>
      <PopupHeader>
        <h3 data-qa="settings-title">{{ $t('settings.title') }}</h3>
        <div class="settings-description" data-qa="settings-subtitle">{{ subtitle }}</div>
      </PopupHeader>
      <PopupBody class="settings-column">
        <FormRow
          v-slot="{ id }"
          :label="$t('settings.officeAddress')"
          :validated="!!officeLocationErrorMessage"
          :errors="[officeLocationErrorMessage]"
        >
          <Autocomplete
            data-qa="office-address-autocomplete"
            v-model="officeLocationObj.office_location_name"
            :inputId="id"
            :placeholder="$t('settings.searchLocation')"
            :fetch="searchPlace"
            @select="onOfficePlaceSelected"
            @input="onOfficeInput"
          >
            <template #item="{ item }">
              <slot :item="item"></slot>
            </template>
          </Autocomplete>
        </FormRow>
        <FormRow
          v-slot="{ id }"
          :label="$t('settings.homeAddress')"
          :validated="!!homeLocationErrorMessage"
          :errors="[homeLocationErrorMessage]"
        >
          <Autocomplete
            data-qa="home-address-autocomplete"
            v-model="homeLocationObj.home_location_name"
            :inputId="id"
            :placeholder="$t('settings.searchLocation')"
            :fetch="searchPlace"
            @select="onHomePlaceSelected"
            @input="onHomeInput"
          >
            <template #item="{ item }">
              <slot :item="item"></slot>
            </template>
          </Autocomplete>
        </FormRow>
        <FormRow v-slot="{ id }" :label="$t('settings.workingDays')">
          <Weekdays
            :interactive="true"
            :inputId="id"
            locale="en"
            :first-day-of-the-week="1"
            v-model="workingDays"
          />
        </FormRow>
        <FormRow v-slot="{ id }" :label="$t('settings.workingHours')">
          <TimePicker :inputId="id" v-model="workingHours" />
        </FormRow>
        <FormRow v-slot="{ id }" :label="$t('settings.lunchHours')">
          <TimePicker v-model="LunchHours" :inputId="id" />
        </FormRow>
        <FormRow v-slot="{ id }" :label="$t('settings.dinnerHours')">
          <TimePicker v-model="dinnerHours" :inputId="id" />
        </FormRow>
      </PopupBody>
      <PopupFooter class="mt-10">
        <PopupButtons>
          <Button
            size="large"
            block
            :disabled="loading"
            :loading="loading"
            icon-right
            @click="applySettings"
            data-qa="apply-location-settings-button"
          >
            {{ $t('settings.applyButton') }}
          </Button>
          <Button
            type="secondary"
            size="large"
            block
            :disabled="loading"
            @click="$emit('close')"
            data-qa="cancel-button"
          >
            {{ $t('settings.cancelButton') }}
          </Button>
        </PopupButtons>
      </PopupFooter>
    </PopupContainer>
  </Popup>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import Button from '@/components/common/buttons/Button.vue'
import Weekdays from '@/components/common/datepicker/Weekdays.vue'
import Autocomplete from '@/components/common/form/Autocomplete.vue'
import FormRow from '@/components/common/form/FormRow.vue'
import Popup from '@/components/common/popup/Popup.vue'
import PopupBody from '@/components/common/popup/PopupBody.vue'
import PopupButtons from '@/components/common/popup/PopupButtons.vue'
import PopupContainer from '@/components/common/popup/PopupContainer.vue'
import PopupFooter from '@/components/common/popup/PopupFooter.vue'
import PopupHeader from '@/components/common/popup/PopupHeader.vue'
import TimePicker from '@/components/common/timepicker/TimePicker.vue'
import { useGoogleAutocompleteSearch } from '@/composables/useGoogleAutocompleteSearch'
import { useShowingToastError } from '@/composables/useShowingToastErrors'
import { useToast } from '@/composables/useToast'
import { TOAST_TIME } from '@/constants/constants'
import { type WorkDaysEnum } from '@/generated/api'
import { useAuthStore } from '@/modules/auth/store'

type OfficeLocation = {
  office_location_name: string
  office_address: string
  office_location_link: string
  latitude_office: number | null
  longitude_office: number | null
}

type HomeLocation = {
  home_location_name: string
  home_address: string
  home_location_link: string
  latitude_home: number | null
  longitude_home: number | null
}

type Time = {
  hours: number
  minutes: number
}

type TimeRange = [start: Time, end: Time]

const props = defineProps<{
  open: boolean
  subtitle: string
}>()

type EmitFunction = (event: 'close') => void
const emit = defineEmits<EmitFunction>()

const toast = useToast()
const { t } = useI18n()
const authStore = useAuthStore()
const { showCommonError } = useShowingToastError()
const officeLocationErrorMessage = ref('')
const homeLocationErrorMessage = ref('')

const userProfile = computed(() => authStore.user?.profile)

const officeLocationObj = ref<OfficeLocation>({
  office_location_name: '',
  office_address: '',
  office_location_link: '',
  latitude_office: null,
  longitude_office: null
})
const homeLocationObj = ref<HomeLocation>({
  home_location_name: '',
  home_address: '',
  home_location_link: '',
  latitude_home: null,
  longitude_home: null
})

const workingDays = ref<WorkDaysEnum[]>([0, 1, 2, 3, 4] as WorkDaysEnum[])
const workingHours = ref<TimeRange>([
  {
    hours: 9,
    minutes: 0
  },
  {
    hours: 18,
    minutes: 0
  }
])
const LunchHours = ref<TimeRange>([
  {
    hours: 12,
    minutes: 0
  },
  {
    hours: 13,
    minutes: 0
  }
])
const dinnerHours = ref<TimeRange>([
  {
    hours: 18,
    minutes: 0
  },
  {
    hours: 20,
    minutes: 0
  }
])

const loading = ref(false)

watchEffect(() => {
  if (props.open) {
    officeLocationObj.value = {
      office_location_name: userProfile.value?.office_location_name || '',
      office_address: userProfile.value?.office_address || '',
      office_location_link: userProfile.value?.office_location_link || '',
      latitude_office: userProfile.value?.latitude_office || null,
      longitude_office: userProfile.value?.longitude_office || null
    }

    homeLocationObj.value = {
      home_location_name: userProfile.value?.home_location_name || '',
      home_address: userProfile.value?.home_address || '',
      home_location_link: userProfile.value?.home_location_link || '',
      latitude_home: userProfile.value?.latitude_home || null,
      longitude_home: userProfile.value?.longitude_home || null
    }
    workingDays.value = userProfile.value?.work_days || [0, 1, 2, 3, 4]
    const workTimeStartParts = userProfile.value?.work_time_start?.split(':')
    const workTimeEndParts = userProfile.value?.work_time_end?.split(':')
    workingHours.value = [
      {
        hours: workTimeStartParts ? parseInt(workTimeStartParts[0]) : 9,
        minutes: workTimeStartParts ? parseInt(workTimeStartParts[1]) : 0
      },
      {
        hours: workTimeEndParts ? parseInt(workTimeEndParts[0]) : 18,
        minutes: workTimeEndParts ? parseInt(workTimeEndParts[1]) : 0
      }
    ]

    const lunchTimeStartParts = userProfile.value?.lunch_time_start?.split(':')
    const lunchTimeEndParts = userProfile.value?.lunch_time_end?.split(':')
    LunchHours.value = [
      {
        hours: lunchTimeStartParts ? parseInt(lunchTimeStartParts[0]) : 12,
        minutes: lunchTimeStartParts ? parseInt(lunchTimeStartParts[1]) : 0
      },
      {
        hours: lunchTimeEndParts ? parseInt(lunchTimeEndParts[0]) : 13,
        minutes: lunchTimeEndParts ? parseInt(lunchTimeEndParts[1]) : 0
      }
    ]

    const dinnerTimeStartParts = userProfile.value?.dinner_time_start?.split(':')
    const dinnerTimeEndParts = userProfile.value?.dinner_time_end?.split(':')
    dinnerHours.value = [
      {
        hours: dinnerTimeStartParts ? parseInt(dinnerTimeStartParts[0]) : 18,
        minutes: dinnerTimeStartParts ? parseInt(dinnerTimeStartParts[1]) : 0
      },
      {
        hours: dinnerTimeEndParts ? parseInt(dinnerTimeEndParts[0]) : 20,
        minutes: dinnerTimeEndParts ? parseInt(dinnerTimeEndParts[1]) : 0
      }
    ]

    officeLocationErrorMessage.value = ''
    homeLocationErrorMessage.value = ''
  }
})

const { selectPlace, searchPlace } = useGoogleAutocompleteSearch()

const onOfficePlaceSelected = async (placeId: string) => {
  const selectedLocation = await selectPlace(placeId)
  officeLocationObj.value = {
    office_location_name: selectedLocation.name,
    office_address: selectedLocation.location_address,
    office_location_link: selectedLocation.remote_link,
    latitude_office: selectedLocation.latitude_location,
    longitude_office: selectedLocation.longitude_location
  } as OfficeLocation
}

const onHomePlaceSelected = async (placeId: string) => {
  const selectedLocation = await selectPlace(placeId)
  homeLocationObj.value = {
    home_location_name: selectedLocation.name,
    home_address: selectedLocation.location_address,
    home_location_link: selectedLocation.remote_link,
    latitude_home: selectedLocation.latitude_location,
    longitude_home: selectedLocation.longitude_location
  } as HomeLocation
}

const onOfficeInput = (value: string) => {
  officeLocationObj.value = {
    office_location_name: value,
    office_address: '',
    office_location_link: '',
    latitude_office: null,
    longitude_office: null
  }
  officeLocationErrorMessage.value = ''
}

const onHomeInput = (value: string) => {
  homeLocationObj.value = {
    home_location_name: value,
    home_address: '',
    home_location_link: '',
    latitude_home: null,
    longitude_home: null
  }
  homeLocationErrorMessage.value = ''
}

const validateLocations = () => {
  if (!officeLocationObj.value.office_location_name) {
    officeLocationErrorMessage.value = t('settings.officeRequired')
  } else if (
    officeLocationObj.value.office_location_name &&
    !officeLocationObj.value.office_address
  ) {
    officeLocationErrorMessage.value = t('settings.locationInvalid')
  }
  if (!homeLocationObj.value.home_location_name) {
    homeLocationErrorMessage.value = t('settings.homeRequired')
  } else if (homeLocationObj.value.home_location_name && !homeLocationObj.value.home_address) {
    homeLocationErrorMessage.value = t('settings.locationInvalid')
  }
}

const applySettings = async () => {
  validateLocations()
  if (officeLocationErrorMessage.value || homeLocationErrorMessage.value) return
  try {
    loading.value = true
    await authStore.updateUserProfile({
      ...officeLocationObj.value,
      ...homeLocationObj.value,
      work_days: workingDays.value,
      work_time_start: `${workingHours.value[0].hours}:${workingHours.value[0].minutes}`,
      work_time_end: `${workingHours.value[1].hours}:${workingHours.value[1].minutes}`,
      lunch_time_start: `${LunchHours.value[0].hours}:${LunchHours.value[0].minutes}`,
      lunch_time_end: `${LunchHours.value[1].hours}:${LunchHours.value[1].minutes}`,
      dinner_time_start: `${dinnerHours.value[0].hours}:${dinnerHours.value[0].minutes}`,
      dinner_time_end: `${dinnerHours.value[1].hours}:${dinnerHours.value[1].minutes}`
    })
    toast.add({
      severity: 'success',
      detail: t('settings.locationUpdatedMessage'),
      ttl: TOAST_TIME
    })
    emit('close')
  } catch (e) {
    showCommonError(e)
  } finally {
    loading.value = false
  }
}
</script>

<style scoped lang="postcss">
.settings-column {
  @apply flex flex-col gap-5 pt-10;
}
.settings-description {
  @apply mt-3 whitespace-pre-line text-center text-lg font-normal;
}
</style>
