<template>
  <div class="layout" id="layout-participant">
    <PageHeader>
      <HeaderDropdown v-if="authStore.isUserLoggedIn()"></HeaderDropdown>
    </PageHeader>
    <div class="layout-content">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import HeaderDropdown from '@/components/page/HeaderDropdown.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import { useAuthStore } from '@/modules/auth/store'
const authStore = useAuthStore()
</script>

<style lang="postcss" scoped>
.layout {
  @apply mx-auto flex h-full max-w-[800px] flex-col overflow-x-hidden;
}

.layout-content {
  @apply flex flex-grow flex-col p-5 md:p-0 md:pb-8;
}
</style>
