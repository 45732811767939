<template>
  <div
    ref="container"
    class="toast"
    :class="[`toast-${position}`]"
    data-qa="toast"
    :style="'--bottom:' + bottom"
  >
    <transition-group name="toast-message" tag="div">
      <ToastMessage v-for="msg of messages" :key="msg.id" :message="msg" @close="remove($event)" />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onBeforeUpdate, onMounted, ref } from 'vue'

import type { Message } from '@/components/common/toast/Message'
import ToastMessage from '@/components/common/toast/ToastMessage.vue'
import { toastService } from '@/components/common/toast/ToastService'

const props = defineProps({
  group: {
    type: String,
    default: null
  },
  position: {
    type: String,
    default: 'bottom-center'
  },
  autoZIndex: {
    type: Boolean,
    default: true
  },
  baseZIndex: {
    type: Number,
    default: 2000
  }
})

const messages = ref<Message[]>([])
const zIndexDelta = ref(999)
const container = ref<HTMLElement>()

const bottom = computed(() => toastService?.offset)

const remove = (message: Message) => {
  messages.value = messages.value.filter((msg) => message !== msg)
}

const updateZIndex = () => {
  if (props.autoZIndex) {
    zIndexDelta.value += 1
    if (container.value) {
      container.value.style.zIndex = String(props.baseZIndex + zIndexDelta.value)
    }
  }
}

const add = (message: Message) => {
  if (!message.group || props.group === message.group) {
    messages.value = [...messages.value, message]
  }
}
const removeGroup = (group: string) => {
  if (props.group === group) {
    messages.value = []
  }
}
const removeAllGroups = () => {
  messages.value = []
}

onMounted(() => {
  toastService.eventBus.on('add', add)
  toastService.eventBus.on('remove-group', removeGroup)
  toastService.eventBus.on('remove-all-groups', removeAllGroups)

  updateZIndex()
})

onBeforeUnmount(() => {
  toastService.eventBus.off('add', add)
  toastService.eventBus.off('remove-group', removeGroup)
  toastService.eventBus.off('remove-all-groups', removeAllGroups)
})

onBeforeUpdate(() => {
  updateZIndex()
})

defineExpose({
  remove,
  toastService
})
</script>

<style scoped lang="postcss">
/* Transition group div */
.toast :deep(div:first-line) {
  @apply flex flex-col items-center;
}

.toast {
  @apply fixed z-toast w-auto opacity-100;
}

.toast-top-right {
  @apply right-5 top-5;
}

.toast-top-left {
  @apply left-5 top-5;
}

.toast-bottom-left {
  @apply bottom-[var(--bottom)] left-5;
}

.toast-bottom-right {
  @apply bottom-[var(--bottom)] right-5;
}

.toast-top-center {
  @apply left-1/2 top-5 -translate-x-1/2;
}

.toast-bottom-center {
  @apply bottom-[var(--bottom)] left-1/2 -translate-x-1/2;
}

.toast-center {
  @apply left-1/2 top-1/2 min-w-[20vw] -translate-x-1/2 -translate-y-1/2;
}

.toast-message-enter-from {
  @apply translate-y-1/2 opacity-0;
}

.toast-message-leave {
  @apply max-h-[1000px];
}

.toast-message-leave-to {
  @apply mb-0 max-h-0 overflow-hidden opacity-0;
}

.toast-message-enter-active {
  transition:
    transform 0.3s,
    opacity 0.3s;
}

.toast-message-leave-active {
  transition:
    max-height 0.45s cubic-bezier(0, 1, 0, 1),
    opacity 0.3s,
    margin-bottom 0.3s;
}
</style>
