import type { RouteRecordRaw } from 'vue-router'

export const NEW_MEETING = 'new-meeting'

export default [
  {
    path: '/new-meeting',
    name: NEW_MEETING,
    component: () => import('./views/NewMeetingView.vue'),
    meta: {
      auth: true
    }
  }
] as RouteRecordRaw[]
