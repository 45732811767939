<template>
  <Select
    ref="selectRef"
    :value="modelValue"
    data-qa="autocomplete"
    class="autocomplete"
    :shown="!dropdownDisabled"
    :content-classes="contentClasses"
    :placement="placement"
    no-auto-focus
  >
    <template #trigger>
      <InputText
        data-qa="autocomplete-text-input"
        :placeholder="placeholder"
        :value="textValue"
        class="autocomplete-text-input"
        :autofocus="autofocus"
        @update:modelValue="onTextInput"
      >
        <template v-if="loading" #after>
          <Loader />
        </template>
      </InputText>
    </template>

    <template v-if="options.length" #default>
      <Option
        v-for="(option, index) in options"
        data-qa="autocomplete-option"
        :key="index"
        hide-icon
        :value="option.value"
        :label="option.label"
        :avatar="option.avatar"
        :subtitle="option.subtitle"
        @click="selectOption(option)"
      />
    </template>
  </Select>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { computed, type PropType, ref, watchEffect } from 'vue'

import InputText from '@/components/common/form/InputText.vue'
import Option from '@/components/common/form/select/Option.vue'
import Select from '@/components/common/form/select/Select.vue'
import Loader from '@/components/common/loader/Loader.vue'
import { useLoading } from '@/composables/useLoading'

export interface AutocompleteOption {
  value: string
  label: string
  avatar?: string
  subtitle?: string
}

const props = defineProps({
  modelValue: { type: String as PropType<string>, required: true },
  fetch: { type: Function as PropType<(val: string) => Promise<AutocompleteOption[]>> },
  fetchDelay: { type: Number, default: 500 },
  minSymbols: { type: Number, default: 1 },
  placeholder: { type: String },
  autofocus: { type: Boolean, default: false },
  placement: { type: String, default: 'bottom-start' }
})

const emit = defineEmits<{
  (e: 'select', value: string): void
  (e: 'input', value: string): void
  (e: 'update:modelValue', value: string): void
}>()

const selectRef = ref<typeof Select | null>(null)
const textValue = ref('')
const options = ref<AutocompleteOption[]>([])

watchEffect(() => {
  textValue.value = props.modelValue
})

const { loading, load } = useLoading(async () => {
  if (props.fetch && textValue.value) {
    options.value = await props.fetch(String(textValue.value))
  }

  if (options.value.length > 0) {
    selectRef.value?.show()
  }
})

const fetchOptions = debounce(load, props.fetchDelay, { leading: true, trailing: true })

const clearOptions = () => {
  options.value = [] as AutocompleteOption[]
}

const onTextInput = async (value: any) => {
  textValue.value = value
  emit('input', textValue.value)

  if (textValue.value.length >= props.minSymbols) {
    await fetchOptions()
  } else {
    clearOptions()
  }
}

const dropdownDisabled = computed(() => options.value.length < 1)
const contentClasses = computed(() => (dropdownDisabled.value ? 'dropdown-content-hidden' : ''))

const selectOption = (option: AutocompleteOption) => {
  textValue.value = option.label

  emit('select', option.value)
  clearOptions()
}
</script>

<style scoped>
.autocomplete-text-input {
  @apply text-black;
}
</style>
