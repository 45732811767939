<template>
  <InputWrapper
    ref="wrapper"
    class="input-wrapper"
    :data-qa="dataQa ? dataQa + '-input' : null"
    :disabled="disabled"
    :error="error"
    :focused="focused || focusedEnforce"
    :no-borders="noBorders"
    :class="{ bordered: !noBorders }"
  >
    <div v-if="slots.before" class="input-before">
      <slot name="before" />
    </div>
    <IMaskComponent
      v-if="mask"
      :id="inputId"
      ref="input"
      :class="classNames"
      :disabled="disabled"
      :value="modelValue"
      :mask="mask"
      :placeholder="showPlaceholder"
      :data-qa="dataQa ? dataQa : null"
      v-bind="attrs"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <input
      v-else
      :id="inputId"
      ref="input"
      :class="classNames"
      :disabled="disabled"
      :value="modelValue"
      :readonly="readonly"
      :placeholder="showPlaceholder"
      :data-qa="dataQa ? dataQa : null"
      v-bind="attrs"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div v-if="slots.after" class="input-after">
      <slot name="after" />
    </div>
  </InputWrapper>
</template>

<script setup lang="ts">
import { computed, onMounted, type PropType, ref, useAttrs, useSlots } from 'vue'
import { IMaskComponent } from 'vue-imask'

import { useInputWrapped, useInputWrappedEmits, useInputWrappedProps } from './composables'
import InputWrapper from './InputWrapper.vue'

const attrs = useAttrs()
const slots = useSlots()

const props = defineProps({
  modelValue: { type: String },
  dataQa: { type: String, default: '' },
  mask: { type: [String, Function] as PropType<string | number | Date> },
  inputId: { type: String },
  transform: { type: Array as PropType<((v: string) => string)[]>, default: () => [] },
  autofocus: { type: Boolean, default: false },
  focusedEnforce: { type: Boolean, default: false },
  noBorders: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  ...useInputWrappedProps()
})

const emits = defineEmits(useInputWrappedEmits())

const { disabled, error, focused, showPlaceholder, onFocus, onBlur, inputHandler } =
  useInputWrapped(props, emits)

const input = ref<HTMLInputElement>()
const wrapper = ref<typeof InputWrapper>()

const onInput = (event: Event): void => {
  inputHandler(event, input.value as HTMLInputElement, props.transform)
}

onMounted(() => {
  if (props.autofocus) {
    input.value?.focus()
  }
})

const classNames = computed(() => ({
  'input-text': true,
  'with-before': slots.before,
  'with-after': slots.after
}))

const focus = () => {
  if (input.value) {
    input.value.focus()
  }
}

defineExpose({
  focus
})
</script>

<style scoped lang="postcss">
.input-text {
  @apply w-full;

  /* autofill breaks radius https://stackoverflow.com/a/67100504/2848264 */
  transition: background-color 2147483647s;

  &.with-before {
    @apply !pl-3;
  }

  &.with-after {
    @apply !pr-3;
  }
}

.input-before,
.input-after {
  @apply text-gray-600 transition-colors;

  &:has(.svg-icon) {
    @apply text-key-500;
  }
}

.input-before {
  @apply flex flex-shrink-0 pl-3;
}

.input-after {
  @apply flex flex-shrink-0 pr-3;
}

.input-wrapper {
  @apply transition-colors;

  &.focused {
    .input-before,
    .input-after {
      @apply text-gray-600;
    }
  }
}
</style>
