import type { RouteRecordRaw } from 'vue-router'

import HeaderLayout from '@/layouts/HeaderLayout.vue'

export const LOGIN = 'auth.login'

export default [
  {
    path: '/login',
    name: LOGIN,
    component: () => import('./views/LoginView.vue'),
    meta: {
      auth: false,
      layout: HeaderLayout
    }
  }
] as RouteRecordRaw[]
