import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

import { ApiError } from '@/generated/api'

import {
  ENV,
  RELEASE_ID,
  SENTRY_DSN,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_TRACE_SAMPLE_RATE
} from './settings'

export function initSentry(app: App, router: Router) {
  if (SENTRY_DSN && typeof SENTRY_DSN === 'string' && ENV && typeof ENV === 'string') {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      environment: ENV,
      release: RELEASE_ID,
      trackComponents: true,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],
      replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
      replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
      tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/calanda\.mpom\.ch\/api/],
      beforeSend(event, hint) {
        // Check if the exception is an ApiError
        const exception = hint.originalException
        if (exception instanceof ApiError) {
          // Don't send ApiError to Sentry
          return null
        }
        // Otherwise, send the event as usual
        return event
      }
    })
  }
}
