<template>
  <button
    v-close-popper="closeOnClick"
    :tabindex="dropdownOpened ? groupTabindex : 0"
    :disabled="disabled"
    :class="{ active, disabled }"
    class="menu-item"
    data-qa="menu-item"
  >
    <div v-if="!hideIcon" class="menu-item-icon">
      <slot name="icon">
        <SvgIcon v-if="renderIcon" :name="renderIcon" />
      </slot>
    </div>
    <div class="menu-label">
      <slot />
    </div>
  </button>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { computed, inject, ref } from 'vue'

import { GROUP_TAB_INDEX } from '@/components/common/dropdown/dropdownSettings'
import SvgIcon from '@/components/common/SvgIcon.vue'

const props = defineProps({
  icon: { type: String },
  closeOnClick: { type: Boolean, default: true },
  active: { type: Boolean, default: false },
  checked: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false }
})

const dropdownOpened = inject<Ref<boolean>>('dropdownOpened', () => ref(false), true)

const groupTabindex = GROUP_TAB_INDEX

const renderIcon = computed(() => {
  if (props.checked) {
    return 'checkmark'
  }
  return props.icon
})
</script>

<style scoped lang="postcss">
.menu-item {
  @apply flex w-full items-center rounded px-3 py-2 transition-colors focus:outline-none;

  &:not(.disabled):not(.active) {
    @apply hover:bg-gray-50 focus:bg-gray-50;
  }

  &.active {
    @apply bg-key-500 text-white;
  }

  &.disabled {
    @apply cursor-not-allowed text-gray-600;
  }
}

.menu-item-icon {
  @apply mr-3 h-5 w-5 flex-shrink-0;

  .icon {
    @apply mt-[-2px];
  }
}

.menu-label {
  @apply flex max-w-full flex-grow items-center overflow-hidden overflow-ellipsis text-left text-base;

  :deep(.subtitle) {
    @apply text-gray-600;
  }
}
</style>
