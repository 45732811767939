<template>
  <SelectHoc ref="selectHoc" :value="modelValue" v-bind="$attrs" @input="onInput">
    <template
      #default="{
        disabled,
        error,
        opened,
        isPlaceholder,
        placeholder,
        selectedLabels,
        onBlur,
        onFocus,
        focused,
        chevronIcon,
        toggleTabindex,
        loading
      }"
    >
      <Skeleton v-if="loading" class="select-skeleton">
        <SkeletonBox class="select-skeleton-box" />
      </Skeleton>

      <slot v-else name="trigger">
        <SelectTrigger
          :disabled="disabled"
          :error="error"
          :focused="opened || focused"
          :trigger-component="triggerComponent"
          :input-id="inputId"
          :is-placeholder="isPlaceholder"
          :placeholder="placeholder"
          :selected-labels="selectedLabels"
          :chevron-icon="chevronIcon"
          :toggle-tabindex="toggleTabindex"
          @blur="onBlur"
          @focus="onFocus"
          @click="$emit('click')"
        >
          <template v-for="(_, slot) of $slots" #[slot]="scope">
            {{ slot }}
            <slot :name="slot" v-bind="scope" />
          </template>
        </SelectTrigger>
      </slot>
    </template>
    <template #dropdown="context">
      <slot v-bind="context" />
    </template>
  </SelectHoc>
</template>

<script setup lang="ts">
import type { Dropdown } from 'floating-vue'
import { type PropType, ref } from 'vue'

import type { SelectValue } from '@/components/common/form/select/select'
import SelectHoc from '@/components/common/form/select/SelectHoc.vue'
import SelectTrigger from '@/components/common/form/select/SelectTrigger.vue'
import Skeleton from '@/components/common/skeleton/Skeleton.vue'
import SkeletonBox from '@/components/common/skeleton/SkeletonBox.vue'

defineProps({
  triggerComponent: { type: String },
  inputId: { type: String },
  modelValue: { type: [Array, Number, String] as PropType<SelectValue>, default: () => {} }
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectValue): void
  (e: 'input', value: SelectValue): void
  (e: 'click'): void
}>()

const selectHoc = ref<typeof Dropdown | null>(null)

const hide = () => {
  selectHoc.value?.hide()
}

const show = () => {
  selectHoc.value?.show()
}

const onInput = (value: SelectValue) => {
  emit('update:modelValue', value)
  emit('input', value)
}

defineExpose({
  hide,
  show
})
</script>

<style scoped>
.select-skeleton {
  @apply w-full;
}

.select-skeleton-box {
  @apply h-[38px] w-full rounded-md;
}
</style>
