import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import i18n from '@/i18n'
import { GOOGLE_MAP_API_KEY } from '@/settings'

export const useGoogleStore = defineStore('map', () => {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&language=${i18n.global.locale.value}&loading=async&libraries=places&callback=initMap`
  script.async = true
  window.initMap = function () {
    // JS API is loaded and available
    setGoogleScriptLoaded(true)
  }
  document.head.appendChild(script)

  const googleScriptLoaded = useLocalStorage('googleScriptLoaded', false, { mergeDefaults: true })

  function setGoogleScriptLoaded(value: true) {
    googleScriptLoaded.value = value
  }
  return {
    googleScriptLoaded,
    setGoogleScriptLoaded
  }
})
