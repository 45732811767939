import dayjs from 'dayjs'
import { ref } from 'vue'

// will not display loader if loaded in time less than this amount
const displayLoaderAfter = 100
// will display loading indicator at least this amount of time to prevent flickering
const minLoaderDisplayTime = 500

/* eslint-disable no-param-reassign, no-return-assign, @typescript-eslint/no-explicit-any */
export const useLoading = <T>(
  func: (...args: any[]) => T,
  options: { immediate?: boolean } = { immediate: false }
) => {
  const { immediate } = options
  const loading = ref(false)

  const wrapperFunction: typeof func = (...args: any[]): T => {
    const startTime = dayjs()
    const toggleOn = () => (loading.value = true)
    const toggleOff = () => (loading.value = false)

    let toggleOnTimeout: ReturnType<typeof setTimeout> | undefined
    if (immediate) {
      toggleOn()
    } else {
      toggleOnTimeout = setTimeout(toggleOn, displayLoaderAfter)
    }

    // To prevent flickering display loading indicator only:
    // - after some initial delay time (if still loading)
    // - for a minimum amount of time (if it was loading more than displayLoaderAfter)
    const finished = () => {
      if (toggleOnTimeout) {
        clearTimeout(toggleOnTimeout)
      }
      const finishTime = dayjs()
      const realTimeSpent = finishTime.millisecond() - startTime.millisecond()
      if (
        realTimeSpent > minLoaderDisplayTime + displayLoaderAfter ||
        realTimeSpent < displayLoaderAfter
      ) {
        toggleOff()
      } else {
        setTimeout(toggleOff, minLoaderDisplayTime - realTimeSpent)
      }
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      const result = func(...args)
      if (result instanceof Promise) {
        result.finally(finished)
      } else {
        finished()
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result
    } catch (e) {
      // we should not handle it in finally since there is special handling for promises inside try body
      finished()
      throw e
    }
  }

  return { load: wrapperFunction, loading }
}
