<template>
  <div class="popup-footer">
    <slot />
  </div>
</template>

<style scoped>
.popup-footer {
  @apply px-8 pb-8;
}
</style>
