import { defineEnv } from 'static-build-env'
const env = defineEnv() as Record<string, unknown>

export const ENV = env.ENV || import.meta.env.VITE_ENV || 'local'
export const RELEASE_ID = env.RELEASE_ID || import.meta.env.VITE_RELEASE_ID || 'unknown'
export const SENTRY_DSN = env.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN || ''
export const GOOGLE_MAP_API_KEY =
  env.GOOGLE_MAP_API_KEY || import.meta.env.VITE_GOOGLE_MAP_API_KEY || ''
export const SENTRY_TRACE_SAMPLE_RATE = Number(
  env.SENTRY_TRACE_SAMPLE_RATE || import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || 0.5
)

export const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = Number(
  env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
    import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
    1.0
)
export const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = Number(
  env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
    0.1
)
export const ENABLE_MICROSOFT_LOGIN = !(
  env.ENABLE_MICROSOFT_LOGIN === 'false' || import.meta.env.VITE_ENABLE_MICROSOFT_LOGIN === 'false'
)

export const GOOGLE_ANALYTICS_ID =
  env.GOOGLE_ANALYTICS_ID || import.meta.env.VITE_GOOGLE_ANALYTICS_ID || ''
