<template>
  <div class="skeleton-box">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.skeleton-box {
  @apply rounded-sm bg-gray-50;
}
</style>
