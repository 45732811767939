import type { RouteRecordRaw } from 'vue-router'

export const HOME = 'home'

export default [
  {
    path: '/',
    name: HOME,
    component: () => import('./views/HomeView.vue'),
    meta: {
      auth: true
    }
  }
] as RouteRecordRaw[]
