<template>
  <component
    :is="component"
    ref="dropdown"
    class="select-wrapper"
    :class="{ focused }"
    :disabled="disabled"
    :content-classes="`${contentClasses} select-menu`"
    v-bind="$attrs"
    @show="onShow"
    @hide="onHide"
    @auto-hide="onAutoHide"
  >
    <template #default="{ toggleTabindex }">
      <slot v-bind="{ ...slotContext, toggleTabindex }" />
    </template>
    <template #dropdown="context">
      <slot name="dropdown" v-bind="{ ...slotContext, ...context }" />
    </template>
  </component>
</template>

<script setup lang="ts">
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import { type Component, computed, inject, type PropType, provide, type Ref, ref } from 'vue'

import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import { DISABLED, ERROR } from '@/components/common/form/composables'
import type { Choice, SelectValue } from '@/components/common/form/select/select'
import { SELECT_CLEAR, SELECT_TOGGLE, SELECT_VALUE } from '@/components/common/form/select/select'

const props = defineProps({
  value: { type: [Array, Number, String] as PropType<SelectValue>, default: () => {} },
  contentClasses: { type: String },
  component: { type: Object as PropType<Component>, default: () => Dropdown },
  placeholder: { type: String },
  isPlaceholder: { type: Boolean as PropType<boolean | null>, default: null },
  multi: { type: Boolean, default: false },
  adjustMaxWidth: { type: Boolean, default: true },
  loading: { type: Boolean, default: false }
})

const emits = defineEmits<{
  (e: 'show'): void
  (e: 'hide'): void
  (e: 'blur'): void
  (e: 'auto-hide'): void
  (e: 'focus'): void
  (e: 'input', value: SelectValue): void
  (e: 'clear'): void
}>()

const dropdown = ref<typeof Dropdown | null>(null)
const selectedLabels = ref<string[]>([`${props.value}`])
const opened = ref(false)
const focused = ref(false)

const disabled = inject<Ref<boolean>>(DISABLED, () => ref(false), true)
const error = inject<Ref<boolean>>(ERROR, () => ref(false), true)

const onShow = () => {
  opened.value = true
  focused.value = true
  const width = (dropdown.value?.$el as HTMLDivElement).offsetWidth
  const wrapper = dropdown.value?.contentWrapper as HTMLDivElement
  if (props.adjustMaxWidth) {
    wrapper.style.maxWidth = `${width}px`
  }
  wrapper.style.minWidth = `${width}px`
  emits('show')
}

const selectToggle = (chosen?: Choice | null, selectedLabel = '') => {
  let newValue = isArray(props.value) ? props.value : [props.value]

  if (!props.multi) {
    selectedLabels.value = []
    newValue = []
  }
  if (chosen !== null && !newValue.includes(chosen)) {
    // select an item
    selectedLabels.value.push(selectedLabel)
    newValue.push(chosen)
  } else {
    // a click to a selected element should deselect it in multi
    selectedLabels.value = selectedLabels.value.filter((v) => !Object.is(v, selectedLabel))
    newValue = newValue.filter((v) => !Object.is(v, chosen))
  }
  // due to reactive selection of an item option's on click is triggered twice
  if (isEqual(newValue, props.value)) {
    return
  }
  if (isArray(props.value)) {
    emits('input', newValue)
  } else {
    emits('input', newValue[0])
  }
}

const onHide = () => {
  opened.value = false
  emits('hide')
}

const hide = () => {
  dropdown.value?.hide()
}

const show = () => {
  dropdown.value?.show()
}

const selectClear = () => {
  selectedLabels.value = []
  hide()
  emits('clear')
  emits('input', [])
}

const onAutoHide = () => {
  opened.value = false
  focused.value = false
  emits('auto-hide')
}

const onFocus = () => {
  focused.value = true
  emits('focus')
}

const onBlur = () => {
  focused.value = false
  emits('blur')
}

const chevronIcon = computed(() => {
  if (opened.value) {
    return 'chevron-up'
  }
  return 'chevron-down'
})

const isPlaceholderCalculated = computed(() => {
  if (props.isPlaceholder !== null) {
    return props.isPlaceholder
  }
  return (isArray(props.value) && !props.value.length) || !props.value
})

const placeholderValue = computed(() => props.placeholder || 'Select')

const slotContext = computed(() => ({
  value: props.value,
  disabled: disabled.value,
  error: error.value,
  opened: opened.value,
  isPlaceholder: isPlaceholderCalculated.value,
  placeholder: placeholderValue.value,
  selectedLabels: selectedLabels.value,
  onBlur,
  onFocus,
  focused: focused.value,
  chevronIcon: chevronIcon.value,
  loading: props.loading
}))

provide(
  SELECT_VALUE,
  computed(() => props.value)
)
provide('opened', opened)
provide(SELECT_TOGGLE, selectToggle)
provide(SELECT_CLEAR, selectClear)

defineExpose({
  hide,
  show
})
</script>

<style scoped>
.select-wrapper {
  @apply w-full;

  :deep(.trigger) {
    @apply max-w-full;
  }
}
</style>
