import axios from 'axios'
import Cookies from 'js-cookie'

import { ApiClient } from '@/generated/api/ApiClient'
import i18n from '@/i18n'
import { MEETING_NOT_FOUND } from '@/modules/accept-event/routes'

import { useAuthStore } from './modules/auth/store'
import router from './router'

let client: ApiClient | null = null
export const useApiClient = () => {
  if (!client) {
    const authStore = useAuthStore()
    client = new ApiClient({
      BASE: '',
      HEADERS: async (): Promise<Record<string, string>> => {
        const headers: Record<string, string> = {}

        // X-CSRFToken is required for Django to accept the request
        const csrfToken = Cookies.get('csrftoken')
        if (csrfToken) {
          headers['X-CSRFToken'] = csrfToken
        }

        // testToken is used for testing purposes
        const testToken = await authStore.getTestToken()
        if (testToken) {
          headers['Authorization'] = `${testToken}`
        }

        // inviteToken is prioritized over testToken and is used for invite links
        const inviteToken = await authStore.getInviteToken()
        if (inviteToken) {
          headers['Authorization'] = `${inviteToken}`
        }

        // Adding browser's timezone to the headers
        headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

        // Adding browser's language to the headers
        headers['Accept-Language'] = i18n.global.locale.value

        return headers
      }
    })
  }
  return client
}

// Set up axios response interceptor
axios.interceptors.response.use(
  (response) => response, // Simply return the response if successful
  (error) => {
    // Check if we've received a 401 or 403 response status
    if ([401, 403].includes(error.response?.status)) {
      // Redirect to the login page
      router.push('/login').catch(() => {
        // Handle the error of pushing the same route (if you are already on the login page)
      })
    } else if (error.response?.status === 404) {
      // Redirect to the 404 page
      router.push({ name: MEETING_NOT_FOUND }).catch(() => {
        // Handle the error of pushing the same route (if you are already on the 404 page)
      })
    } else if (error.response?.status >= 500) {
      // Redirect to the 500 page
      router.push('/500').catch(() => {
        // Handle the error of pushing the same route (if you are already on the 500 page)
      })
    }

    // Ensure the rejection reason is an instance of Error
    if (!(error instanceof Error)) {
      error = new Error(error)
    }

    // Always reject the promise if there was an error
    return Promise.reject(error)
  }
)
