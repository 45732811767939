<template>
  <Dropdown ref="dropdown" v-bind="$attrs">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'

import Dropdown from '@/components/common/dropdown/Dropdown.vue'

const props = defineProps({
  narrow: { type: Boolean, default: false },
  hideDropdown: { type: Boolean, default: false, required: false }
})

type EmitFunction = (event: 'update:hideDropdown', value: boolean) => void
const emit = defineEmits<EmitFunction>()

const dropdown = ref<typeof Dropdown | null>(null)

watchEffect(() => {
  if (props.hideDropdown) {
    emit('update:hideDropdown', false)
    dropdown.value?.hide()
  }
})
</script>
