<template>
  <div :class="getWeeksClass()">
    <div
      v-for="(day, index) in weekDays"
      :key="'w' + index"
      :data-qa="`datepicker-weekday-${index}`"
      :class="getWeekClass(index)"
      @click="toggleDay(index)"
    >
      {{ day }}
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref } from 'vue'

const props = defineProps({
  locale: { type: String, required: true },
  firstDayOfTheWeek: { type: Number, required: true },
  modelValue: { type: Array, default: () => [] },
  interactive: { type: Boolean, default: false }
})

const activeDays = ref(props.modelValue)

const getWeeksClass = () => {
  if (!props.interactive) {
    return 'calendar-weekdays'
  }
  return 'interactive-weekdays'
}

const getWeekClass = (index: number) => {
  if (!props.interactive) {
    return 'calendar-weekday'
  }
  if (props.modelValue.includes(index)) {
    return 'interactive-weekday active-weekday'
  }
  return 'interactive-weekday inactive-weekday'
}

const toggleDay = (dayIndex: number) => {
  const currentIndex = activeDays.value.indexOf(dayIndex)
  if (currentIndex >= 0) {
    activeDays.value.splice(currentIndex, 1)
  } else {
    activeDays.value.push(dayIndex)
  }
}

const weekDays = computed(() => {
  const localeData = dayjs().locale(props.locale).localeData()
  const weekdays = localeData.weekdaysMin()
  const before = weekdays.slice(0, props.firstDayOfTheWeek)
  const after = weekdays.slice(props.firstDayOfTheWeek)
  return [...after, ...before]
})
</script>

<style scoped>
.calendar-weekdays {
  @apply grid grid-cols-7 py-[10px];
}

.interactive-weekdays {
  @apply grid grid-cols-7 gap-1;
}

.interactive-weekday {
  @apply cursor-pointer rounded-md border py-[8px] text-center font-base font-bold;
  &:hover {
    @apply bg-key-200;
  }
  &:active {
    @apply bg-key-300;
  }
}

.calendar-weekday {
  @apply text-center font-base font-bold text-gray-600;
}

.inactive-weekday {
  @apply text-gray-600;
}

.active-weekday {
  @apply text-key-600;
}
</style>
