<template>
  <component
    :is="tag"
    class="button"
    :class="{
      [size]: true,
      [type]: true,
      [color]: true,
      'icon-left': (icon || loading) && !iconRight,
      'icon-right': (icon || loading) && iconRight,
      'with-chevron': chevron,
      'icon-only': iconOnlyDetected,
      'block-button': block
    }"
    :disabled="disabled"
    :type="buttonType"
    v-bind="$attrs"
  >
    <SvgIcon v-if="!loading && icon && !iconRight" class="icon" :name="icon" />
    <div v-if="loading && !iconRight" class="icon loading-icon">
      <Loader class="loader" />
    </div>
    <span class="label">
      <slot v-if="!iconOnlyDetected" />
    </span>
    <div v-if="loading && iconRight" class="icon loading-icon">
      <Loader class="loader" />
    </div>
    <SvgIcon v-if="!loading && icon && iconRight" class="icon" :name="icon" />
    <SvgIcon v-if="chevron" class="chevron" :name="chevron" />
  </component>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, useSlots } from 'vue'

import Loader from '@/components/common/loader/Loader.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'

const slots = useSlots()

const props = defineProps({
  size: { type: String as PropType<'small' | 'medium' | 'large'>, default: 'medium' },
  type: { type: String as PropType<'primary' | 'secondary' | 'text'>, default: 'primary' },
  color: {
    type: String as PropType<'key' | 'gray' | 'danger' | 'success'>,
    default: 'key'
  },
  tag: { type: String, default: 'button' },
  icon: { type: String },
  chevron: { type: String },
  loading: { type: Boolean, default: false },
  iconRight: { type: Boolean, default: false },
  iconOnly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  buttonType: { type: String as PropType<'button' | 'submit' | 'reset'>, default: 'button' },
  textSize: { type: String, default: '14px' }
})

const iconOnlyDetected = computed(() => props.iconOnly || !slots.default)
</script>

<style scoped lang="postcss">
.button {
  @apply inline-flex select-none items-center justify-center whitespace-nowrap rounded-full align-middle text-base font-bold transition-all;

  .loader {
    @apply h-full w-full text-white;
  }

  &.block-button {
    @apply flex w-full;
  }

  .icon {
    @apply flex-shrink-0;
  }

  &:not(.icon-only) {
    .label {
      @apply flex-grow;
    }
  }

  /* extra CSS selectors specificity needed to reset shadows in one place */
  &.small.primary[disabled],
  &.medium.primary[disabled],
  &.large.primary[disabled],
  &.small.secondary[disabled],
  &.medium.secondary[disabled],
  &.large.secondary[disabled] {
    @apply cursor-not-allowed shadow-none hover:shadow-none active:shadow-none;
  }

  /* Fixes the ink outside of rounded borders issue in safari, see https://stackoverflow.com/a/23735769/2848264 */
  transform: translateZ(0);

  /* optimize browser work on hover */
  transition-property: box-shadow, background-color, border-color, color, transform;

  &.small {
    @apply rounded-sm text-sm;

    padding: 3px 8px;

    &.primary {
      @apply shadow-small-dark hover:shadow-small-dark-hover active:shadow-small-dark;
    }

    &.secondary {
      @apply shadow-small-light hover:shadow-small-light-hover active:shadow-small-light;
    }

    .icon {
      @apply h-4 w-4;
    }

    .label {
      @apply overflow-ellipsis text-sm font-bold;
    }
  }

  &.medium {
    padding: 7px 16px;
    @apply rounded-md;

    &.primary {
      @apply shadow-medium-dark hover:shadow-medium-dark-hover active:shadow-medium-dark;
    }

    &.secondary {
      @apply shadow-medium-light hover:shadow-medium-light-hover active:shadow-medium-light;
    }

    .icon {
      @apply block h-5 w-5;
    }

    .label {
      @apply overflow-ellipsis text-base font-bold;
    }
  }

  &.large {
    @apply rounded-xl text-lg-button;

    padding: 11px 20px;

    &.primary {
      @apply shadow-medium-dark hover:shadow-medium-dark-hover active:shadow-medium-dark;
    }

    &.secondary {
      @apply shadow-medium-light hover:shadow-medium-light-hover active:shadow-medium-light;
    }

    .icon {
      @apply block h-6 w-6;
    }

    .label {
      @apply overflow-ellipsis text-lg-button font-bold;
    }
  }

  &.primary {
    @apply text-black;

    &[disabled] {
      @apply text-black-40;
    }

    .loader {
      @apply text-black;
    }
  }

  &.secondary {
    @apply border border-gray-100 bg-white hover:border-gray-200 hover:bg-gray-50 active:border-gray-300 active:bg-gray-100-60;

    &[disabled] {
      @apply border-gray-200 bg-gray-50 hover:border-gray-200 hover:bg-gray-50;
    }
  }

  &.text {
    @apply border-transparent bg-transparent shadow-none hover:bg-black-5 hover:shadow-none active:bg-black-10;

    &[disabled] {
      @apply border-transparent bg-transparent hover:border-transparent hover:bg-transparent;
    }
  }

  &.key {
    &.primary {
      @apply bg-key-500 hover:bg-key-600 active:bg-key-700;

      &[disabled] {
        @apply bg-key-600 text-black-40 hover:bg-key-600;
      }
    }

    &.secondary,
    &.text {
      @apply text-key-600 hover:text-key-700 active:text-key-800;

      &[disabled] {
        @apply text-key-500-60 hover:text-key-500-60;
      }

      .loader {
        @apply text-key-500 hover:text-key-600 active:text-key-700;
      }
    }
  }

  &.success {
    &.primary {
      @apply bg-success-500 hover:bg-success-600 active:bg-success-700;

      &[disabled] {
        @apply bg-success-600 text-white-60 hover:bg-success-600;
      }
    }

    &.secondary,
    &.text {
      @apply text-success-500 hover:text-success-600 active:text-success-700;

      &[disabled] {
        @apply text-success-500-60 hover:text-success-500-60;
      }

      .loader {
        @apply text-success-500 hover:text-success-600 active:text-success-700;
      }
    }
  }

  &.danger {
    &.primary {
      @apply bg-danger-500 text-white hover:bg-danger-600 active:bg-danger-700;

      &[disabled] {
        @apply bg-danger-600 hover:bg-danger-600 hover:text-white-60;
      }
      .loader {
        @apply text-white;
      }
    }

    &.secondary,
    &.text {
      @apply text-danger-500 hover:text-danger-600 active:text-danger-700;

      &[disabled] {
        @apply text-danger-500-60 hover:text-danger-500-60;
      }

      .loader {
        @apply text-danger-500 hover:text-danger-600 active:text-danger-700;
      }
    }
  }

  &.gray {
    &.secondary,
    &.text {
      @apply text-gray-600 hover:text-gray-700 active:text-gray-800;

      &[disabled] {
        @apply text-gray-600-60 hover:text-gray-600-60;
      }

      .loader {
        @apply text-gray-600 hover:text-gray-700 active:text-gray-800;
      }
    }
  }

  &.small.icon-left {
    @apply px-2;

    .icon {
      @apply mr-2;
    }
  }

  &.small.icon-right {
    @apply pr-2;

    .icon {
      @apply ml-2;
    }
  }

  &.small.with-chevron {
    @apply pr-2;

    .chevron {
      @apply m-0 ml-2 h-[10px] w-[10px];
    }
  }

  &.medium.icon-left {
    @apply pl-2;

    .icon {
      @apply mr-4;
    }
  }

  &.medium.icon-right {
    @apply pr-2;

    .icon {
      @apply ml-4;
    }
  }

  &.medium.with-chevron {
    @apply pr-3;

    .chevron {
      @apply m-0 ml-4 h-3 w-3;
    }
  }

  &.large.icon-left {
    @apply pl-3;

    .icon {
      @apply mr-5;
    }
  }

  &.large.icon-right {
    @apply pr-3;

    .icon {
      @apply ml-5;
    }
  }

  &.large.with-chevron {
    @apply pr-3;

    .chevron {
      @apply m-0 ml-5 h-4 w-4;
    }
  }

  &.icon-only {
    @apply flex-shrink-0 rounded-full;

    &.icon-left .icon,
    &.icon-right .icon {
      @apply m-0;
    }

    &.button {
      @apply h-9 w-9 p-0;

      &.small {
        @apply h-6 w-6;
      }

      &.large {
        @apply h-12 w-12;
      }
    }
  }
}
</style>
