<template>
  <Popup :open="open" @close="$emit('close')">
    <PopupContainer>
      <PopupHeader>
        <h3 class="title" style="text-align: center">{{ $t('consentPopup.header') }}</h3>
      </PopupHeader>
      <PopupBody class="pt-3">
        <div class="flex flex-col items-center text-lg">
          <p>
            {{ $t('consentPopup.body', { provider: provider }) }}
          </p>
        </div>
      </PopupBody>
      <PopupFooter class="mt-10">
        <PopupButtons>
          <Button color="key" size="large" block @click="handleAgree">
            {{ $t('consentPopup.provideButton') }}
          </Button>
          <Button
            v-if="hidePermanentCloseButton"
            type="secondary"
            size="large"
            block
            @click="$emit('permanentClose')"
          >
            {{ $t('consentPopup.denyButton') }}
          </Button>
          <Button type="secondary" size="large" block @click="$emit('close')">
            {{ $t('consentPopup.closeButton') }}
          </Button>
        </PopupButtons>
      </PopupFooter>
    </PopupContainer>
  </Popup>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import Button from '@/components/common/buttons/Button.vue'
import Popup from '@/components/common/popup/Popup.vue'
import PopupBody from '@/components/common/popup/PopupBody.vue'
import PopupButtons from '@/components/common/popup/PopupButtons.vue'
import PopupContainer from '@/components/common/popup/PopupContainer.vue'
import PopupFooter from '@/components/common/popup/PopupFooter.vue'
import PopupHeader from '@/components/common/popup/PopupHeader.vue'
import { useAuthStore } from '@/modules/auth/store'
import { loginAzure, loginGoogle } from '@/services/authenticationService'
const authStore = useAuthStore()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps<{
  open: boolean
  hidePermanentCloseButton?: boolean
}>()

const emit = defineEmits(['close', 'agree', 'permanentClose'])
const provider = computed(() => authStore.getProvider())

const handleAgree = () => {
  if (provider.value === 'Microsoft') {
    loginAzure()
  } else if (provider.value === 'Google') {
    loginGoogle()
  }
  emit('agree')
}
</script>
