export const enum RequirementStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Empty = 'empty'
}

export type Requirement = {
  status: RequirementStatus
  text: string
}

/* number in values are same as in owasp library */
export enum PasswordTests {
  MinLength = 0,
  MaxLength = 1,
  Repeated = 2,
  Lowercase = 3,
  Uppercase = 4,
  Digit = 5,
  Special = 6,
  LowerUpperDigit = 7
}

export const PasswordTestsI18n: Record<number, string> = {
  [PasswordTests.MinLength]: 'min-length',
  [PasswordTests.MaxLength]: 'max-length',
  [PasswordTests.Repeated]: 'repeated',
  [PasswordTests.Lowercase]: 'lowercase',
  [PasswordTests.Uppercase]: 'uppercase',
  [PasswordTests.Digit]: 'digit',
  [PasswordTests.Special]: 'special',
  [PasswordTests.LowerUpperDigit]: 'lower-upper-digit'
}
