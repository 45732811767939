<template>
  <div v-if="hasAny" class="field-messages">
    <Transitions name="slide">
      <div>
        <Notification
          v-for="(err, index) in unref(errors)"
          :key="'err-' + index"
          class="error-message"
          severity="error"
          inline
        >
          {{ err }}
        </Notification>
        <Notification
          v-for="(warn, index) in unref(warnings)"
          :key="'warn-' + index"
          severity="warning"
          inline
        >
          {{ warn }}
        </Notification>
        <Notification
          v-for="(info, index) in infos"
          :key="'info-' + index.toString()"
          severity="info"
          inline
        >
          {{ info }}
        </Notification>
        <Notification
          v-for="(requirement, index) in unref(requirements)"
          :key="'req-' + index"
          :icon="statusIcons[requirement.status]"
          severity="neutral"
          inline
          class="requirement"
          :class="{ [requirement.status]: true }"
        >
          {{ requirement.text }}
        </Notification>
      </div>
    </Transitions>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, type Ref, ref, unref } from 'vue'

import Transitions from '@/components/common/transition/Transitions.vue'

import { ERRORS, type FormMessages, INFOS, REQUIREMENTS, WARNINGS } from './composables'
import { RequirementStatus } from './constants'
import Notification from './Notification.vue'

const _errors = inject(ERRORS, () => ref([]), true)
const errors = normalizeStrings(_errors)
const _infos = inject(INFOS, () => ref([]), true)
const infos = normalizeStrings(_infos)
const _warnings = inject(WARNINGS, () => ref([]), true)
const warnings = normalizeStrings(_warnings)
const requirements = inject(REQUIREMENTS, () => ref([]), true)

const statusIcons = {
  [RequirementStatus.Approved]: 'circle-checkmark',
  [RequirementStatus.Rejected]: 'circle-crossmark',
  [RequirementStatus.Empty]: 'circle-checkmark'
}

const hasAny = computed(
  () =>
    unref(errors).length > 0 ||
    unref(infos).length > 0 ||
    unref(warnings).length > 0 ||
    unref(requirements).length > 0
)

function normalizeStrings(msgs: Ref<FormMessages>): Ref<string[]> {
  return computed(() => {
    if (!msgs.value) return []
    if (typeof msgs.value === 'string') return [msgs.value]
    return msgs.value.filter(Boolean) as string[]
  })
}
</script>

<style scoped lang="postcss">
.field-messages {
  @apply ml-2 w-full text-base text-gray-800;

  :deep(.notification) {
    &:first-child {
      @apply mt-2;
    }

    @apply mt-1;
  }
}

.error-message {
  @apply text-gray-800;
}

.requirement {
  &.approved {
    :deep(.svg-icon) {
      @apply text-success-500;
    }
  }

  &.rejected {
    :deep(.svg-icon) {
      @apply text-danger-500;
    }
  }

  &.empty {
    :deep(.svg-icon) {
      @apply text-gray-300;
    }
  }
}
</style>
