import type { LocationQueryValue } from 'vue-router'

export const loginAzure = (redirectUrl: string | LocationQueryValue[] | null = null) => {
  console.log('Attempting Microsoft login...')
  const currentHost = window.location.origin
  const url = redirectUrl ?? currentHost
  const next = `?next=${url}`
  window.location.href = `${currentHost}/api/auth/login/azuread-oauth2/${next}`
}

export const loginGoogle = (redirectUrl: string | LocationQueryValue[] | null = null) => {
  console.log('Attempting Google login...')
  const currentHost = window.location.origin
  const url = redirectUrl ?? currentHost
  const next = `?next=${url}`
  window.location.href = `${currentHost}/api/auth/login/google-oauth2/${next}`
}
