import type { RouteRecordRaw } from 'vue-router'

export const PRIVACY_POLICY_EN = 'privacy-policy-en'
export const PRIVACY_POLICY_DE = 'privacy-policy-de'

export default [
  {
    path: '/privacy-policy/en',
    name: PRIVACY_POLICY_EN,
    component: () => import('./views/PrivacyPolicyEN.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/privacy-policy/de',
    name: PRIVACY_POLICY_DE,
    component: () => import('./views/PrivacyPolicyDE.vue'),
    meta: {
      auth: false
    }
  }
] as RouteRecordRaw[]
