import './assets/css/main.css'

import VueDatePicker from '@vuepic/vue-datepicker'
import FloatingVue from 'floating-vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'

import ToastPlugin from '@/components/common/toast/ToastService'
import i18n from '@/i18n'
import { GOOGLE_ANALYTICS_ID } from '@/settings'

import App from './App.vue'
import router from './router'
import { initSentry } from './sentry'

const app = createApp(App)

initSentry(app, router)

app.use(
  VueGtag,
  {
    appName: 'Calanda',
    pageTrackerScreenviewEnabled: true,
    config: { id: GOOGLE_ANALYTICS_ID }
  },
  router
)

app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(ToastPlugin)
app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      distance: 9
    }
  }
})
app.component('VueDatePicker', VueDatePicker)

app.mount('#app')
