import type { RouteRecordRaw } from 'vue-router'

export const TERMS_OF_SERVICE = 'terms-of-service'

export default [
  {
    path: '/terms-of-service',
    name: TERMS_OF_SERVICE,
    component: () => import('./views/TermsOfService.vue'),
    meta: {
      auth: false
    }
  }
] as RouteRecordRaw[]
