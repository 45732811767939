<template>
  <DropdownMenuItem class="select-option" :icon="renderIcon" :hide-icon="hideIcon" @click="onClick">
    <template #icon>
      <slot name="icon" />
    </template>
    <slot v-bind="{ label }">
      <div class="menu-label-content" v-if="!avatar">
        <div>{{ label }}</div>
        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="menu-label-content-with-avatar" v-if="avatar">
        <div class="avatar-container" v-if="avatar">
          <img :src="avatar" alt="Avatar" class="avatar" />
        </div>
        <div class="flex flex-col text-nowrap">
          <div>{{ label }}</div>
          <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        </div>
      </div>
    </slot>
  </DropdownMenuItem>
</template>

<script setup lang="ts">
import isArray from 'lodash/isArray'
import { computed, inject, onMounted, ref, watch } from 'vue'

import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import type { Choice } from '@/components/common/form/select/select'
import { SELECT_CLEAR, SELECT_TOGGLE, SELECT_VALUE } from '@/components/common/form/select/select'

const props = withDefaults(
  defineProps<{
    value?: Choice
    label?: string
    avatar?: string
    clickable?: boolean
    displaySelection?: boolean
    hideIcon?: boolean
    icon?: string
    clear?: boolean
    subtitle?: string
  }>(),
  { clickable: true, displaySelection: true, icon: '' }
)

type EmitFunction = (event: 'click') => void
const emits = defineEmits<EmitFunction>()

const selectValue = inject(SELECT_VALUE, () => ref({}), true)
const selectToggle = inject(SELECT_TOGGLE)
const selectClear = inject(SELECT_CLEAR)

const isSelected = computed(() => {
  if (!props.displaySelection) {
    return false
  }

  // value can be undefined only if it's a reset option
  if (isArray(selectValue.value)) {
    return props.value !== undefined && selectValue.value.includes(props.value)
  }
  return Object.is(selectValue.value, props.value)
})

const renderIcon = computed(() => {
  if (isSelected.value) {
    return 'checkmark'
  }
  return props.icon
})

const onClick = () => {
  if (props.clickable) {
    if (props.clear) {
      selectClear?.()
    } else {
      selectToggle?.(props.value, props.label)
    }
  }
  emits('click')
}

const init = () => {
  if (isSelected.value) {
    onClick()
  }
}

watch(isSelected, init)

onMounted(init)
</script>
<style>
.avatar {
  @apply h-9 w-9;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
.menu-label-content-with-avatar {
  display: flex;
  align-items: center;
}
.avatar-container {
  min-width: 48px;
}
</style>
