<template>
  <div class="layout" id="layout-default">
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
.layout {
  @apply mx-auto flex h-full max-w-[560px] flex-col overflow-x-hidden pb-5 md:pb-8;
}
</style>
