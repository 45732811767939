<template>
  <Toast></Toast>
  <component :is="layout">
    <RouterView />
  </component>
</template>

<script setup lang="ts">
import { computed, defineComponent } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Toast from '@/components/common/toast/Toast.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

const route = useRoute()
const layout = computed(
  (): ReturnType<typeof defineComponent> => route.meta.layout || DefaultLayout
)
</script>
