export const TOAST_TIME = 4000

export const QUERY_DATE_FORMAT = 'YYYY-MM-DD'

export const NEW_MEETING_STEPS = {
  EVENT_TYPE: 1,
  PARTICIPANTS: 2,
  NAME: 3,
  LOCATION_TYPE: 4,
  LOCATION_ADDRESS: 5,
  DURATION: 6,
  CALENDAR_SLOTS: 7,
  EMAIL_PREVIEW: 8
}

export const LOCATION_TYPES = {
  OFFICE: 'OFFICE',
  REMOTELY: 'REMOTELY',
  OUTSIDE: 'OUTSIDE'
  // HOME: 'HOME',
}

export const EVENT_TYPES = {
  BUSINESS: 'BUSINESS',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER'
}

export const REQUEST_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
}

export const SLOTS_STATUSES = {
  SUGGESTED: 'SUGGESTED',
  PENDING: 'PENDING',
  SELECTED: 'SELECTED',
  ACCEPTED: 'ACCEPTED'
}

export const SLOTS_TYPES = {
  EVENT: 'Event',
  SLOT: 'Slot'
}

export const LOCATION_TYPES_TITLES = {
  OFFICE: 'constants.office',
  HOME: 'constants.home',
  OUTSIDE: 'constants.outside',
  REMOTELY: 'constants.remotely'
}

export const EVENT_TYPES_TITLES = {
  BUSINESS: 'constants.business',
  LUNCH: 'constants.lunch',
  DINNER: 'constants.dinner'
}

export const DURATION_VALUES = {
  '00:30:00': '00:30:00',
  '00:45:00': '00:45:00',
  '01:00:00': '01:00:00',
  '01:30:00': '01:30:00',
  '02:00:00': '02:00:00',
  '02:30:00': '02:30:00',
  '03:00:00': '03:00:00',
  '03:30:00': '03:30:00',
  '04:00:00': '04:00:00',
  '05:00:00': '05:00:00',
  '06:00:00': '06:00:00',
  '07:00:00': '07:00:00',
  '08:00:00': '08:00:00'
}

export const DURATION_TITLES = {
  '00:30:00': '30m',
  '00:45:00': '45m',
  '01:00:00': '1h',
  '01:30:00': '1.5h',
  '02:00:00': '2h',
  '02:30:00': '2.5h',
  '03:00:00': '3h',
  '03:30:00': '3.5h',
  '04:00:00': '4h',
  '05:00:00': '5h',
  '06:00:00': '6h',
  '07:00:00': '7h',
  '08:00:00': 'All day'
}
