import { useToast } from '@/composables/useToast'
import { TOAST_TIME } from '@/constants/constants'
import { ApiError } from '@/generated/api'

export function useShowingToastError() {
  const toast = useToast()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showCommonError = (e: any) => {
    if (e instanceof ApiError) {
      if (e.body.detail) {
        toast.add({ severity: 'error', detail: e.body.detail, ttl: TOAST_TIME })
      } else if (e.body) {
        const errorMessages = Object.values(e.body).flat().join(' ')
        toast.add({ severity: 'error', detail: errorMessages, ttl: TOAST_TIME })
      }
    }
  }

  return {
    showCommonError
  }
}
