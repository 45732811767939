import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ToastService } from '@/components/common/toast/ToastService'

export const ToastSymbol = Symbol()

export function useToast(): ToastService {
  const Toast = inject(ToastSymbol)
  const { t } = useI18n()

  if (!Toast) {
    throw new Error(t('errors.noToastProvided'))
  }

  return <ToastService>Toast
}
