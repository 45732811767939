<template>
  <div
    class="toast-message"
    :class="containerClass"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-message-content">
      <Notification
        :severity="message.severity"
        :summary="message.summary"
        :detail="message.detail"
        :icon="message.icon"
      />
      <div v-if="message.closable !== false" class="close-button" @click="close">
        <div class="close-content">
          <div v-if="message.closeText" class="close-text">{{ message.closeText }}</div>
          <SvgIcon v-else name="crossmark" :size="16" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref } from 'vue'

import Notification from '@/components/common/form/Notification.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import type { Message } from '@/components/common/toast/Message'

const closeTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const props = defineProps({
  message: { type: Object as PropType<Message>, required: true }
})

type EmitFunction = (event: 'close', message: Message) => void
const emit = defineEmits<EmitFunction>()

const containerClass = computed(() => `toast-message-${props.message.severity}`)

const clearCloseTimeout = () => {
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value)
    closeTimeout.value = null
  }
}
const close = () => {
  clearCloseTimeout()
  emit('close', props.message)
}

onMounted(() => {
  if (props.message.ttl) {
    closeTimeout.value = setTimeout(close, props.message.ttl)
  }
})

onBeforeUnmount(clearCloseTimeout)
</script>

<style scoped lang="postcss">
.toast-message {
  border-radius: 6px;
  @apply mb-1 w-[calc(100vw-40px)] bg-gray-50 text-base text-black sm:w-auto;
}

.toast-message.toast-message-info {
  @apply bg-info-50;
}

.toast-message :deep(.notification) {
  @apply flex-1 bg-transparent pr-0 sm:min-w-[24rem] sm:max-w-[24rem];

  .notification-icon {
    @apply text-black;
  }
}

.toast-message :deep(.notification.info .notification-icon) {
  @apply text-info-500;
}

.toast-message.toast-message-success {
  @apply bg-success-50;
}

.toast-message :deep(.notification.success .notification-icon) {
  @apply text-success-500;
}

.toast-message.toast-message-warning {
  @apply bg-warning-50;
}

.toast-message :deep(.notification.warning .notification-icon) {
  @apply text-warning-500;
}

.toast-message.toast-message-error {
  @apply bg-danger-50;
}

.toast-message :deep(.notification.error .notification-icon) {
  @apply text-danger-500;
}

.toast-message-content {
  @apply flex items-start;
}

.close-button {
  @apply flex cursor-pointer self-stretch transition-colors;
}

.close-content {
  @apply flex flex-col justify-center self-stretch p-3 text-black transition-colors hover:text-black;
}

.close-text {
  @apply whitespace-nowrap px-2 font-bold;
}
</style>
