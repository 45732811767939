<template>
  <div
    ref="el"
    class="form-row-field"
    :class="{
      error: hasError,
      disabled,
      [computedLayout]: true,
      radio: type === 'radio',
      switch: type === 'switch',
      custom: type === 'custom',
      'no-label': !label,
      'row-section': rowSection,
      'content-after': contentAfter
    }"
  >
    <slot name="label">
      <label class="form-row-label" :for="id || generatedId">
        <Icon v-if="labelIcon" :name="labelIcon" class="label-icon" :size="20" />
        <span>{{ label }}</span>
        <Icon
          v-if="label && tooltip"
          v-tooltip.top="tooltip"
          name="circle-question"
          class="icon"
          :size="16"
        />
      </label>
    </slot>
    <div class="content">
      <slot
        v-bind="{
          id: id || generatedId,
          inputId: id || generatedId,
          error: hasError,
          disabled,
          label
        }"
      />
      <FormFieldMessages />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core'
import { computed, type PropType, ref } from 'vue'

import Icon from '@/components/common/SvgIcon.vue'

import { useFormElement, useFormElementProps, useUniqueId } from './composables'
import FormFieldMessages from './FormFieldMessages.vue'

const props = defineProps({
  label: { type: String },
  id: { type: String },
  type: { type: String as PropType<'radio' | 'switch' | 'custom'> },
  contentAfter: { type: Boolean, default: false },
  layout: { type: String as PropType<'auto' | 'vertical' | 'horizontal'>, default: 'auto' },
  tooltip: { type: String },
  labelIcon: { type: String },
  rowSection: { type: Boolean, default: false },
  ...useFormElementProps()
})

const generatedId = useUniqueId('form-field-')

const { hasError } = useFormElement(props)

const detectedLayout = ref<'vertical' | 'horizontal'>('horizontal')

const computedLayout = computed(() =>
  props.layout === 'auto' ? detectedLayout.value : props.layout
)

const el = ref()
const observedEl = computed(() => (props.layout === 'auto' ? el.value : null))

useResizeObserver(observedEl, (entries) => {
  requestAnimationFrame(() => onResize(entries[0].contentRect))
})

function onResize(data: { width: number; height: number }) {
  detectedLayout.value = data.width < 500 ? 'vertical' : 'horizontal'
}
</script>

<style scoped lang="postcss">
.form-row-field {
  @apply flex items-start gap-2 transition-all;

  &.row-section {
    @apply mb-10;
  }

  &.content-after {
    @apply mb-3;
  }

  .form-row-label {
    @apply min-w-[160px] max-w-[160px] break-words text-base font-bold text-gray-700;
  }

  .icon {
    @apply cursor-pointer text-gray-700 transition-colors hover:text-gray-700;
  }

  .label-icon {
    @apply mr-1 cursor-default text-gray-400 hover:text-gray-600;
  }

  &.vertical {
    @apply flex-col;

    .content,
    .form-row-label {
      @apply w-full min-w-0 max-w-full;
    }
  }

  &.horizontal {
    @apply flex-row items-start justify-start;

    label {
      @apply pt-2;
    }

    .content {
      @apply flex-grow;
    }

    &.custom {
      label {
        @apply pt-0;
      }
    }
  }

  &.switch {
    .content {
      @apply pt-[6px];
    }
  }

  &.radio {
    .content {
      @apply pt-2;
    }
  }
}
</style>
