<template>
  <div class="popup-buttons">
    <slot />
  </div>
</template>

<style scoped>
.popup-buttons {
  @apply flex flex-col gap-5;
}
</style>
