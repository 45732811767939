<template>
  <div>
    <DropdownMenu placement="bottom-end">
      <template #default="{ toggleTabindex }">
        <Avatar
          data-qa="user-avatar"
          :url="avatarUrl"
          :name="userName"
          :tabindex="toggleTabindex"
        ></Avatar>
      </template>
      <template #dropdown>
        <DropdownMenuItem data-qa="settings-item" icon="cog" @click="showSettings = true">
          {{ $t('headerDropdown.settings') }}
        </DropdownMenuItem>
        <DropdownMenuItem
          v-if="showConsentButton"
          data-qa="consent-item"
          :icon="getIcon"
          @click="showConsentPopup = true"
          >{{ $t('headerDropdown.serviceConsent') }}
        </DropdownMenuItem>
        <DropdownMenuItem data-qa="language-settings-item" icon="globe" @click="changeLanguage">
          {{ getChangeLanguageText() }}
        </DropdownMenuItem>
        <Separator />
        <DropdownMenuItem data-qa="logout-item" icon="log-out" @click="logout">
          {{ $t('headerDropdown.logout') }}
        </DropdownMenuItem>
      </template>
    </DropdownMenu>
    <SettingsPopup
      :open="showSettings"
      :subtitle="$t('headerDropdown.subtitle')"
      @close="showSettings = false"
    ></SettingsPopup>
    <ConsentPopup
      :open="showConsentPopup"
      :hide-permanent-close-button="authStore.hidePermanentCloseButton()"
      @permanentClose="authStore.setDoNotShowConsent()"
      @close="showConsentPopup = false"
    ></ConsentPopup>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import Avatar from '@/components/common/avatar/Avatar.vue'
import DropdownMenu from '@/components/common/dropdown/DropdownMenu.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import Separator from '@/components/common/dropdown/Separator.vue'
import ConsentPopup from '@/components/page/ConsentPopup.vue'
import SettingsPopup from '@/components/page/SettingsPopup.vue'
import i18n from '@/i18n'
import { LOGIN } from '@/modules/auth/routes'
import { useAuthStore } from '@/modules/auth/store'

const authStore = useAuthStore()
const router = useRouter()
const { t } = useI18n()

const showSettings = ref(false)
const showConsentPopup = ref(false)
const showConsentButton = computed(() => !authStore.hasAllScopes())
const getIcon = computed(() => {
  const provider = authStore.getProvider()
  if (!provider) {
    console.error('Provider is not defined')
    return
  }
  if (provider === 'Microsoft') {
    return 'microsoft'
  }
  if (provider === 'Google') {
    return 'google'
  }
  return provider
})

const changeLanguage = () => {
  const currentLanguage = authStore.user?.profile?.language || 'en'
  const newLanguage = currentLanguage === 'en' ? 'de' : 'en'
  authStore.updateUserProfile({ language: newLanguage })
  i18n.global.locale.value = newLanguage
  dayjs.locale(i18n.global.locale.value)
  localStorage.setItem('lang', i18n.global.locale.value)
}

const getChangeLanguageText = () => {
  const currentLanguage = authStore.user?.profile?.language || 'en'
  return currentLanguage === 'en'
    ? t('headerDropdown.switchToGerman')
    : t('headerDropdown.switchToEnglish')
}

const userName = computed(() => {
  const firstName = authStore.user?.first_name || ''
  const lastName = authStore.user?.last_name || ''

  // Use the user's email if both first_name and last_name are null or empty
  if (!firstName && !lastName) {
    return authStore.user?.email || ''
  }

  return `${firstName} ${lastName}`.trim()
})
const avatarUrl = computed(() => authStore.user?.profile?.avatar)

const logout = () => {
  authStore.logout()
  router.push({ name: LOGIN })
}
</script>
