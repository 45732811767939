import type { InjectionKey, Ref } from 'vue'

export type Choice = string | object | number
export type SelectValue = Choice[] | Choice

export const SELECT_VALUE = Symbol('selectValue') as InjectionKey<Ref<SelectValue>>
export const SELECT_CLEAR = Symbol('selectClear') as InjectionKey<() => void>
export const SELECT_TOGGLE = Symbol('selectToggle') as InjectionKey<
  (choice: Choice | undefined, label: string | undefined) => void
>
